<template>
  <div class="edit-information">
    <q-page-container>
      <q-page v-if="!loading">
        <div class="head">
          <div class="left">
            {{ information.name }} <span>{{ information.information }}</span>
          </div>
          <div class="right">
            <q-btn
              label="Save"
              class="btn-save"
              type="button"
              @click="onSubmit"
            >
              <q-icon class="save-icon" />
            </q-btn>
          </div>
        </div>
        <div class="form-container">
          <q-form class="q-gutter-md">
            <div class="form-header">
              <div>Information types displayed by default</div>
              <p>Total {{ information.total }} information item types</p>
            </div>
            <div class="radio-wrapper">
              <q-radio
                v-model="information.radio"
                val="all"
                label="Show all"
                @input="showAll"
              />
              <q-radio
                v-model="information.radio"
                val="hide"
                label="Hide all"
                @input="hideAll"
              />
              <q-radio
                v-model="information.radio"
                val="manually"
                label="Manually choose the items to display"
              />
            </div>
            <div class="toggle-wrapper">
              <q-toggle
                v-for="(item, index) in information.toggle"
                v-model="item.toggle"
                :label="item.label"
                :key="index"
                left-label
                @input="information.radio = 'manually'"
              />
            </div>
          </q-form>
        </div>
      </q-page>
    </q-page-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'EditInformation',
  data() {
    return {
      loading: true,
      information: {
        name: '',
        information: '',
        total: 0,
        radio: 'manually',
        toggle: {}
      }
    }
  },
  computed: {
    ...mapGetters('users', [
      'getUserPlaceholders',
      'getLoadingUserPlaceholders',
      'getErrorUserPlaceholders',
      'getErrorSendingPlaceholderStatus'
    ])
  },
  async created() {
    if (this.getUserPlaceholders.length) {
      this.setInformation()
    } else {
      await this.fetchUserPlaceholders()
      if (this.getErrorUserPlaceholders.length) return

      this.setInformation()
    }
  },
  methods: {
    ...mapActions('users', [
      'fetchUserPlaceholders',
      'sendUserPlaceholderStatus'
    ]),
    async onSubmit() {
      const data = this.information.toggle.map(item => {
        return {
          dataCode: item.dataCode,
          displayStatus: item.toggle ? 'SHOW' : 'HIDE'
        }
      })

      await this.sendUserPlaceholderStatus(data)
      if (this.getErrorSendingPlaceholderStatus.length) return

      this.$router.push('/informations-management')
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    async setInformation() {
      const { name } = this.$route.params
      const result =
        this.getUserPlaceholders.find(item => item.categoryName === name) ||
        null

      if (!result) this.$router.push('/informations-management')

      const toggleList = result.categoryLabels.map(item => {
        const obj = {
          ...item
        }

        obj.toggle = item.displayStatus === 'SHOW'
        obj.label = item.dataLabel

        return obj
      })

      this.information = {
        name: result.categoryName,
        information: result.information,
        total: result.categoryLabels.length,
        radio: 'manually',
        toggle: toggleList
      }

      this.loading = false
    },
    showAll() {
      this.information.toggle.map(item => (item.toggle = true))
    },
    hideAll() {
      this.information.toggle.map(item => (item.toggle = false))
    }
  },
  watch: {
    getErrorUserPlaceholders: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getErrorSendingPlaceholderStatus: function(message) {
      if (message && message.length) this.showNotify(message)
    }
  }
}
</script>
